<template>
  <ModalProto
    :data="modalData"
    :show-state-handler="initFields"
    class="modal--employee"
    ref="modal"
  >
    <FormField
      v-for="spec of fieldsSpecification"
      :key="spec.name"
      :ref="spec.name"
      :default="fields[spec.name]"
      :specification="spec"
      :disabled="spec.name === 'email' && mode === 'edit'"
      @update="setField"
    />
  </ModalProto>
</template>

<script>
import ModalProto from "./ModalProto";
import { mapGetters } from "vuex";
import { REGEXP } from "@/assets/regexp";
import { QUALIFICATION } from "@/assets/enums";
import FormField from "@/components/modal/FormField.vue";
import dayjs from "dayjs";

const { PHONE, EMAIL, TELEGRAM, DATE, FIRST_NAME_LATIN, LAST_NAME_LATIN } =
  REGEXP;

export default {
  name: "EmployeeModal",
  components: {
    ModalProto,
    FormField,
  },
  data() {
    return {
      mode: "create",
      isActive: true,
      fields: {},
      employeeId: null,
    };
  },
  created() {
    this.initFields();
  },
  computed: {
    ...mapGetters({
      getActivitiesForSelect: "planningsStore/getActivitiesForSelect",
    }),
    modalData() {
      return {
        title:
          this.mode === "create"
            ? "Создание пользователя"
            : "Редактирование пользователя",
        actions: [
          {
            id: "accept",
            title: `Принять`,
            handler: () => {
              this.putData();
            },
          },
          {
            id: "cancel",
            title: `Отменить`,
            handler: () => {
              this.close();
            },
          },
        ],
        hasOverlay: true,
      };
    },
    fieldsSpecification() {
      const spec = [
        {
          name: "email",
          label: "E-mail",
          require: true,
          type: "input",
          autofocus: true,
          validator: (value) => {
            return {
              success: EMAIL.test(value),
              message: !EMAIL.test(value)
                ? "Проверьте email на корректность"
                : "",
            };
          },
        },
        {
          name: "is_active",
          label: "Активен",
          type: "toggle",
          options: {
            true: "да",
            false: "нет",
          },
          isHidden: this.mode === "create",
        },
        {
          name: "first_name",
          label: "Имя",
          require: true,
          type: "input",
          validator: (value) => {
            return {
              success: value && 0 < value.length && value.length <= 50,
              message:
                value && !value.length
                  ? "Минимальная длина 1 символ"
                  : value && value.length > 50
                  ? "Максимальная длина 50 символов"
                  : "",
            };
          },
        },
        {
          name: "last_name",
          label: "Фамилия",
          require: true,
          type: "input",
          validator: (value) => {
            return {
              success: value && 0 < value.length && value.length <= 150,
              message:
                value && !value.length
                  ? "Минимальная длина 1 символ"
                  : value && value.length > 150
                  ? "Максимальная длина 150 символов"
                  : "",
            };
          },
        },
        {
          name: "first_name_eng",
          label: "Имя на латинице",
          require: true,
          type: "input",
          validator: (value) => {
            return {
              success: value && FIRST_NAME_LATIN.test(value),
              message:
                value && !value.length
                  ? "Минимальная длина 1 символ"
                  : value && value.length > 50
                  ? "Максимальная длина 50 символов"
                  : !FIRST_NAME_LATIN.test(value)
                  ? "Проверьте что все символы допустимы (буквы латиницей, тире, пробел)"
                  : "",
            };
          },
        },
        {
          name: "last_name_eng",
          label: "Фамилия на латинице",
          require: true,
          type: "input",
          validator: (value) => {
            return {
              success: value && 0 < LAST_NAME_LATIN.test(value),
              message:
                value && !value.length
                  ? "Минимальная длина 1 символ"
                  : value && value.length > 150
                  ? "Максимальная длина 150 символов"
                  : !LAST_NAME_LATIN.test(value)
                  ? "Проверьте что все символы допустимы (буквы латиницей, тире, пробел)"
                  : "",
            };
          },
        },
        {
          name: "birthdate",
          label: "Дата рождения",
          require: true,
          type: "date",
          validator: (value) => ({
            success: DATE.test(value),
            message: !DATE.test(value)
              ? "Проверьте правильность даты: дд.мм.гггг"
              : "",
          }),
        },
        {
          name: "qualification",
          label: "Квалификация",
          require: false,
          type: "select",
          options: this.$getLocalEnum("QUALIFICATION", this.optionsFormatter),
        },
        {
          name: "english_level",
          label: "Уровень английского",
          require: false,
          type: "select",
          options: this.$getLocalEnum("EN_LEVELS", this.optionsFormatter),
        },
        {
          name: "position",
          label: "Позиция",
          require: true,
          type: "select",
          options: this.getActivitiesForSelect,
          validator: (value) => {
            return {
              success: !!value,
              message: "Отсутствует обязательное значение",
            };
          },
        },
        {
          name: "phone",
          label: "Телефон",
          require: true,
          type: "input",
          validator: (value) => {
            return {
              success: PHONE.test(value),
              message:
                value.length < 3
                  ? "Минимальная длина 3 символа"
                  : value.length > 15
                  ? "Максимальная длина 15 символов"
                  : value[0] !== "+"
                  ? "Телефонный номер должен начинаться со знака '+'"
                  : !/^\+[0-9]+$/.test(value)
                  ? "Номер телефона должен содержать только знак '+' и цифры"
                  : "",
            };
          },
        },
        {
          name: "telegram_contact",
          label: "Telegram",
          require: true,
          type: "input",
          validator: (value) => {
            return {
              success: TELEGRAM.test(value),
              message:
                5 > value.length
                  ? "Минимальная длина 5 символов"
                  : value.length > 32
                  ? "Максимальная длина 32 символа"
                  : !TELEGRAM.test(value)
                  ? "Проверьте что все символы допустимы. Можно использовать"
                  : "",
            };
          },
        },
        {
          name: "fulltime",
          label: "Занятость",
          type: "toggle",
          options: {
            true: "full-time",
            false: "part-time",
          },
        },
      ];
      return spec.filter((field) => !field.isHidden);
    },
    fieldsState() {
      const state = { values: {}, isCompleted: true };
      Object.entries(this.fields).forEach(([field, value]) => {
        if (value !== null) {
          state.values[field] = value;
        }
        const spec = this.fieldsSpecification.find(
          (fieldSpec) => fieldSpec.name === field
        );

        if (
          state.isCompleted &&
          spec &&
          spec.require &&
          spec.validator &&
          !spec.validator(value).success
        ) {
          state.isCompleted = false;
        }
      });
      return state;
    },
  },
  methods: {
    initFields(isOpen) {
      if (isOpen) {
        return;
      }
      this.fields = {
        is_active: true,
        last_name: null,
        last_name_eng: null,
        first_name: null,
        first_name_eng: null,
        birthdate: null,
        qualification: QUALIFICATION.NOT_SPECIFIED,
        english_level: null,
        position: null,
        phone: null,
        email: null,
        telegram_contact: null,
        fulltime: true,
      };
    },
    open({ mode = "create", data }) {
      this.mode = mode;
      const modal = this.$refs.modal;
      modal.open();
      modal.setPosition("center");
      if (data) {
        this.employeeId = data.id;
        data.position = data.position?.id;
        Object.keys(this.fields).forEach((field) => {
          if (field === "birthdate" && data[field]) {
            this.fields[field] = dayjs(data[field]).format("DD.MM.YYYY");
            return;
          }
          this.fields[field] = data[field] ?? this.fields[field];
        });
      }
    },
    close() {
      this.$refs.modal.close({ callback: this.initFields });
    },
    setField({ id, value }) {
      this.fields[id] = value;
    },
    checkFields() {
      Object.values(this.$refs).forEach((ref) => {
        if (Array.isArray(ref)) {
          ref[0]?.check();
        }
      });
    },
    async putData() {
      this.checkFields();
      if (!this.fieldsState.isCompleted) {
        return;
      }

      const fields = { ...this.fieldsState.values };

      if (this.mode === "create") {
        delete fields.is_active;
      }

      this.$emit("put-data", {
        mode: this.mode,
        id: this.employeeId,
        fields,
      });
      this.close();
    },
    optionsFormatter(dictionary) {
      return Object.entries(dictionary || {}).map(([key, value]) => ({
        id: key,
        name: value,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/modal.scss";

.modal--employee {
  position: fixed;
  width: 550px;
}

.modal__toggle {
  width: 250px;
}
</style>
